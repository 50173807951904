import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "the-content" }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = {
  key: 0,
  class: "vault-docs"
}
const _hoisted_4 = {
  key: 1,
  class: "text-center my-4"
}
const _hoisted_5 = { class: "flex justify-content-center flex-wrap" }
const _hoisted_6 = {
  id: "sub-header",
  class: "relative"
}
const _hoisted_7 = ["href", "target", "onClick"]
const _hoisted_8 = {
  key: 1,
  class: "flex flex-row flex-wrap"
}
const _hoisted_9 = ["href", "target"]
const _hoisted_10 = { class: "flex align-items-center breadcrumb-data" }
const _hoisted_11 = { class: "flex align-items-center" }
const _hoisted_12 = ["href", "target", "onClick"]
const _hoisted_13 = { class: "flex align-items-center" }
const _hoisted_14 = { class: "text-primary" }
const _hoisted_15 = ["disabled", "onClick"]
const _hoisted_16 = { key: 0 }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { class: "flex align-items-center justify-content-start w-9" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = {
  key: 0,
  class: "flex justify-content-end"
}
const _hoisted_21 = ["href"]
const _hoisted_22 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppToolbar = _resolveComponent("AppToolbar")!
  const _component_DocTableView = _resolveComponent("DocTableView")!
  const _component_DocGrid = _resolveComponent("DocGrid")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_DocUploader = _resolveComponent("DocUploader")!
  const _component_Activities = _resolveComponent("Activities")!
  const _component_Image = _resolveComponent("Image")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_SharedModal = _resolveComponent("SharedModal")!
  const _component_AnalyticsModal = _resolveComponent("AnalyticsModal")!
  const _component_FolderTemplateModal = _resolveComponent("FolderTemplateModal")!
  const _component_OwnerModal = _resolveComponent("OwnerModal")!
  const _component_DownloadModal = _resolveComponent("DownloadModal")!
  const _component_PDFViewer = _resolveComponent("PDFViewer")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_TableView = _resolveComponent("TableView")!
  const _component_ContextMenu = _resolveComponent("ContextMenu")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AppToolbar, {
      current_view: _ctx.self.current_view,
      "onUpdate:current_view": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.self.current_view) = $event)),
      current_sort: _ctx.self.current_sort,
      "onUpdate:current_sort": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.self.current_sort) = $event)),
      view_activities: _ctx.self.activities.view,
      "onUpdate:view_activities": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.self.activities.view) = $event)),
      selected_items: _ctx.self.selectedDocuments,
      "onUpdate:selected_items": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.self.selectedDocuments) = $event)),
      permission: _ctx.self.permissionType,
      search_placeholder: "Search Shared With Me",
      breadcrumb_home: _ctx.self.breadcrumbHome,
      breadcrumb_items: _ctx.self.breadcrumbItems,
      options: _ctx.self.toolbarOptions,
      filter: _ctx.self.filterOptions,
      add_options: _ctx.self.default_context_menu,
      type_options: _ctx.self.filterTypeOptions,
      loading: _ctx.self.loading,
      suggestions: _ctx.self.suggestions,
      onCreateNew: _ctx.createNew,
      onReloadData: _ctx.reloadAllData,
      onDownloadItem: _ctx.downloadSelectedDocuments,
      onSubmitFilterData: _ctx.searchDocuments,
      onGetSuggestions: _ctx.getFilterSuggestions
    }, null, 8, ["current_view", "current_sort", "view_activities", "selected_items", "permission", "breadcrumb_home", "breadcrumb_items", "options", "filter", "add_options", "type_options", "loading", "suggestions", "onCreateNew", "onReloadData", "onDownloadItem", "onSubmitFilterData", "onGetSuggestions"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass([[_ctx.self.activities.view ? 'col-8' : 'col-12 width-transaction'], "p-0 relative top-0"])
        }, [
          _createVNode(_component_DocUploader, {
            ref: "docUploader",
            view_dropzone: _ctx.self.view_dropzone,
            "onUpdate:view_dropzone": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.self.view_dropzone) = $event)),
            permission: _ctx.self.permissionType,
            is_share_page: true,
            is_root: _ctx.self.is_root,
            parent: _ctx.self.parent_doc,
            selected_document: _ctx.self.selected_document,
            dragged_selected_document: _ctx.self.dragged_selected_document,
            items: _ctx.self.documents,
            loading: _ctx.self.loading_docs,
            canceled_upload: _ctx.self.canceled_upload,
            onMediaUploaded: _ctx.onUploadComplete,
            onViewDefaultContext: _ctx.viewDefaultContext
          }, {
            documents: _withCtx(() => [
              (_ctx.self.documents.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _withDirectives(_createVNode(_component_DocTableView, {
                      ref: "docTable",
                      breadcrumb_up_to: _ctx.self.breadcrumb_up_to,
                      selected_documents: _ctx.self.selectedDocuments,
                      "onUpdate:selected_documents": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.self.selectedDocuments) = $event)),
                      selected_document: _ctx.self.selected_document,
                      "onUpdate:selected_document": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.self.selected_document) = $event)),
                      disabled_selection: _ctx.self.disabled_selection,
                      "onUpdate:disabled_selection": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.self.disabled_selection) = $event)),
                      permission: _ctx.self.permissionType,
                      context_items: _ctx.self.menu_items,
                      loading: _ctx.self.loading_docs,
                      items: _ctx.self.documents,
                      fields: _ctx.self.table_fields,
                      pagination: _ctx.self.pagination,
                      activities_view: _ctx.self.activities.view,
                      clickable: true,
                      drag_selector: _ctx.self.drag_selector,
                      moving_doc: _ctx.self.moving_doc,
                      highlight_doc: _ctx.self.highlight_doc,
                      view_parent: true,
                      onLoadMoreDocs: _ctx.getDocument,
                      onLoadDocDetail: _ctx.getDocumentDetail,
                      onUpdateDocument: _ctx.updateDocument,
                      onUpdateName: _ctx.updateDocName,
                      onCreateDocument: _ctx.createNewDocument,
                      onUpdateMenu: _cache[7] || (_cache[7] = ($event: any) => {
                    _ctx.getMenuItems();
                    _ctx.self.highlight_doc = {};
                  }),
                      onUploadOnDocument: _ctx.uploadOnDocument,
                      onDragOverSub: _ctx.dragedOverDocument,
                      onDragLeaveSub: _ctx.dragedLeaveDocument,
                      onDraggedDoc: _ctx.draggedDocument,
                      onStopDragged: _ctx.stopDrag
                    }, null, 8, ["breadcrumb_up_to", "selected_documents", "selected_document", "disabled_selection", "permission", "context_items", "loading", "items", "fields", "pagination", "activities_view", "drag_selector", "moving_doc", "highlight_doc", "onLoadMoreDocs", "onLoadDocDetail", "onUpdateDocument", "onUpdateName", "onCreateDocument", "onUploadOnDocument", "onDragOverSub", "onDragLeaveSub", "onDraggedDoc", "onStopDragged"]), [
                      [_vShow, _ctx.self.current_view == 'list']
                    ]),
                    _withDirectives(_createVNode(_component_DocGrid, {
                      ref: "docGrid",
                      breadcrumb_up_to: _ctx.self.breadcrumb_up_to,
                      selected_documents: _ctx.self.selectedDocuments,
                      "onUpdate:selected_documents": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.self.selectedDocuments) = $event)),
                      selected_document: _ctx.self.selected_document,
                      "onUpdate:selected_document": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.self.selected_document) = $event)),
                      update_listing: _ctx.self.update_listing,
                      "onUpdate:update_listing": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.self.update_listing) = $event)),
                      disabled_selection: _ctx.self.disabled_selection,
                      "onUpdate:disabled_selection": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.self.disabled_selection) = $event)),
                      permission: _ctx.self.permissionType,
                      context_items: _ctx.self.menu_items,
                      loading: _ctx.self.loading_docs,
                      items: _ctx.self.documents,
                      activities_view: _ctx.self.activities.view,
                      drag_selector: _ctx.self.drag_selector,
                      moving_doc: _ctx.self.moving_doc,
                      create: _ctx.self.create,
                      highlight_doc: _ctx.self.highlight_doc,
                      dragged_over_doc: _ctx.self.dragged_selected_document,
                      view_parent: true,
                      onUpdateMenu: _cache[12] || (_cache[12] = ($event: any) => {
                    _ctx.getMenuItems();
                    _ctx.self.highlight_doc = {};
                  }),
                      onLoadMoreDocs: _ctx.getDocument,
                      onLoadDocDetail: _ctx.getDocumentDetail,
                      onUpdateDocument: _ctx.updateDocument,
                      onUpdateName: _ctx.updateDocName,
                      onCreateDocument: _ctx.createNewDocument,
                      onUploadOnDocument: _ctx.uploadOnDocument,
                      onDragOverSub: _ctx.dragedOverDocument,
                      onDragLeaveSub: _ctx.dragedLeaveDocument,
                      onDraggedDoc: _ctx.draggedDocument,
                      onStopDragged: _ctx.stopDrag
                    }, null, 8, ["breadcrumb_up_to", "selected_documents", "selected_document", "update_listing", "disabled_selection", "permission", "context_items", "loading", "items", "activities_view", "drag_selector", "moving_doc", "create", "highlight_doc", "dragged_over_doc", "onLoadMoreDocs", "onLoadDocDetail", "onUpdateDocument", "onUpdateName", "onCreateDocument", "onUploadOnDocument", "onDragOverSub", "onDragLeaveSub", "onDraggedDoc", "onStopDragged"]), [
                      [_vShow, _ctx.self.current_view == 'grid']
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.self.loading_docs)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_ProgressSpinner, {
                      style: {"width":"20px","height":"20px"},
                      strokeWidth: "5",
                      animationDuration: ".5s",
                      "aria-label": "Loading"
                    })
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["view_dropzone", "permission", "is_root", "parent", "selected_document", "dragged_selected_document", "items", "loading", "canceled_upload", "onMediaUploaded", "onViewDefaultContext"])
        ], 2),
        (_ctx.self.activities.view)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "col-4",
              onContextmenu: _cache[16] || (_cache[16] = _withModifiers(() => {}, ["stop"]))
            }, [
              _createVNode(_component_Activities, {
                active_tab: _ctx.self.activities.active_tab,
                "onUpdate:active_tab": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.self.activities.active_tab) = $event)),
                doc: _ctx.self.selected_document,
                activities: _ctx.self.activities.data,
                loading: _ctx.self.activities.loading,
                view_parent: true,
                onOpenShareModal: _cache[15] || (_cache[15] = ($event: any) => (_ctx.self.share_modal = true)),
                onLoadMoreActivities: _ctx.getActivities,
                onViewActivityDetail: _ctx.viewActivityDetail,
                permission: _ctx.self.selected_document.permission
              }, null, 8, ["active_tab", "doc", "activities", "loading", "onLoadMoreActivities", "onViewActivityDetail", "permission"])
            ], 32))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_Dialog, {
        visible: _ctx.self.preview_img,
        "onUpdate:visible": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.self.preview_img) = $event)),
        modal: "",
        style: { width: '100em', height: '100em' },
        maximizable: ""
      }, {
        header: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.self.selected_document.name), 1)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Image, {
              src: _ctx.self.properties.$getFullPath(_ctx.self.selected_document.path),
              alt: _ctx.self.selected_document.name,
              class: ""
            }, null, 8, ["src", "alt"])
          ])
        ]),
        _: 1
      }, 8, ["visible"]),
      (_ctx.self.share_modal)
        ? (_openBlock(), _createBlock(_component_SharedModal, {
            key: 0,
            view_modal: _ctx.self.share_modal,
            "onUpdate:view_modal": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.self.share_modal) = $event)),
            permission: _ctx.self.permissionType,
            doc: _ctx.self.selected_document,
            onSharedDocument: _ctx.updateShared,
            onShareRevoked: _ctx.revokedShared
          }, null, 8, ["view_modal", "permission", "doc", "onSharedDocument", "onShareRevoked"]))
        : _createCommentVNode("", true),
      (_ctx.self.analytic_modal)
        ? (_openBlock(), _createBlock(_component_AnalyticsModal, {
            key: 1,
            view_modal: _ctx.self.analytic_modal,
            "onUpdate:view_modal": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.self.analytic_modal) = $event)),
            doc: _ctx.self.selected_document,
            onPreviewDocument: _ctx.previewDocument
          }, null, 8, ["view_modal", "doc", "onPreviewDocument"]))
        : _createCommentVNode("", true),
      (_ctx.self.folder_template_modal)
        ? (_openBlock(), _createBlock(_component_FolderTemplateModal, {
            key: 2,
            view_modal: _ctx.self.folder_template_modal,
            "onUpdate:view_modal": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.self.folder_template_modal) = $event)),
            doc: _ctx.self.selected_document,
            onUsedTemplate: _ctx.reloadAllData
          }, null, 8, ["view_modal", "doc", "onUsedTemplate"]))
        : _createCommentVNode("", true),
      (_ctx.self.owner_modal)
        ? (_openBlock(), _createBlock(_component_OwnerModal, {
            key: 3,
            view_modal: _ctx.self.owner_modal,
            "onUpdate:view_modal": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.self.owner_modal) = $event)),
            doc: _ctx.self.selected_document
          }, null, 8, ["view_modal", "doc"]))
        : _createCommentVNode("", true),
      _createVNode(_component_DownloadModal, {
        view_modal: _ctx.self.download.modal,
        "onUpdate:view_modal": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.self.download.modal) = $event)),
        message: _ctx.self.download.message
      }, null, 8, ["view_modal", "message"]),
      (_ctx.self.preview_pdf)
        ? (_openBlock(), _createBlock(_component_PDFViewer, {
            key: 4,
            preview: _ctx.self.preview_pdf,
            pdf_page: _ctx.self.pdf_page,
            onClosePdf: _cache[23] || (_cache[23] = ($event: any) => {
          _ctx.self.preview_pdf = false;
          _ctx.self.pdf_page.view = false;
        }),
            pdf_url: 
          _ctx.self.selected_document.proxy_path
            ? _ctx.self.selected_document.proxy_path
            : _ctx.self.selected_document.path
        ,
            doc: _ctx.self.selected_document,
            user_session: _ctx.self.user_session
          }, null, 8, ["preview", "pdf_page", "pdf_url", "doc", "user_session"]))
        : _createCommentVNode("", true),
      _createVNode(_component_Dialog, {
        visible: _ctx.self.moving_doc.view,
        "onUpdate:visible": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.self.moving_doc.view) = $event)),
        modal: "",
        style: { width: '100em', height: '100em' },
        maximizable: "",
        id: "move-doc"
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            (_ctx.self.move_data.breadcrumbs.length < 3)
              ? (_openBlock(), _createBlock(_component_Breadcrumb, {
                  key: 0,
                  home: _ctx.self.move_data.breadcrumbHome,
                  model: _ctx.self.move_data.breadcrumbs
                }, {
                  item: _withCtx(({ label, item, props }) => [
                    (item.to)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          to: item.to,
                          onClick: item.command
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.action)), [
                              _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.icon)), null, 16),
                              _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17)
                            ], 16)
                          ]),
                          _: 2
                        }, 1032, ["to", "onClick"]))
                      : (_openBlock(), _createElementBlock("a", _mergeProps({
                          key: 1,
                          href: item.url,
                          target: item.target
                        }, props.action, {
                          onClick: item.command
                        }), [
                          (item.icon)
                            ? (_openBlock(), _createElementBlock("span", _normalizeProps(_mergeProps({ key: 0 }, props.icon)), null, 16))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17)
                        ], 16, _hoisted_7))
                  ]),
                  _: 1
                }, 8, ["home", "model"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_Breadcrumb, {
                    home: _ctx.self.move_data.breadcrumbHome,
                    model: _ctx.self.move_data.breadcrumbs.slice(0, 1)
                  }, {
                    item: _withCtx(({ label, item, props }) => [
                      (item.to)
                        ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 0,
                            to: item.to,
                            onClick: item.command
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.action)), [
                                _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.icon)), null, 16),
                                _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17)
                              ], 16)
                            ]),
                            _: 2
                          }, 1032, ["to", "onClick"]))
                        : (_openBlock(), _createElementBlock("a", _mergeProps({
                            key: 1,
                            href: item.url,
                            target: item.target
                          }, props.action), [
                            (item.icon)
                              ? (_openBlock(), _createElementBlock("span", _normalizeProps(_mergeProps({ key: 0 }, props.icon)), null, 16))
                              : _createCommentVNode("", true),
                            _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17)
                          ], 16, _hoisted_9))
                    ]),
                    _: 1
                  }, 8, ["home", "model"]),
                  _createElementVNode("div", _hoisted_10, [
                    _cache[29] || (_cache[29] = _createElementVNode("i", { class: "pi pi-chevron-right px-2" }, null, -1)),
                    _createVNode(_component_Button, {
                      type: "button",
                      text: "",
                      icon: "pi pi-ellipsis-h",
                      class: "breadcrumb-overflow p-0 w-auto",
                      onClick: _ctx.togglemoveToBreadcrumbItem
                    }, null, 8, ["onClick"]),
                    _createVNode(_component_OverlayPanel, {
                      class: "breadcrumb-panel",
                      ref: "moveTobreadcrumbItem"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Listbox, {
                          options: 
                      _ctx.self.move_data.breadcrumbs.slice(
                        1,
                        _ctx.self.move_data.breadcrumbs.length - 1
                      )
                    ,
                          class: "w-full md:w-14rem"
                        }, {
                          option: _withCtx((slotProps) => [
                            _createElementVNode("div", {
                              class: "w-100 h-100",
                              onClick: _cache[24] || (_cache[24] = 
//@ts-ignore
(...args) => (_ctx.togglemoveToBreadcrumbItem && _ctx.togglemoveToBreadcrumbItem(...args)))
                            }, [
                              (slotProps.option.to)
                                ? (_openBlock(), _createBlock(_component_router_link, {
                                    key: 0,
                                    to: slotProps.option.to,
                                    onClick: slotProps.option.command
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_11, [
                                        _createElementVNode("div", null, _toDisplayString(slotProps.option.label), 1)
                                      ])
                                    ]),
                                    _: 2
                                  }, 1032, ["to", "onClick"]))
                                : (_openBlock(), _createElementBlock("a", {
                                    key: 1,
                                    href: slotProps.option.url,
                                    target: slotProps.option.target,
                                    onClick: slotProps.option.command
                                  }, [
                                    _createElementVNode("span", null, _toDisplayString(slotProps.option.label), 1)
                                  ], 8, _hoisted_12))
                            ])
                          ]),
                          _: 1
                        }, 8, ["options"])
                      ]),
                      _: 1
                    }, 512),
                    _createElementVNode("div", _hoisted_13, [
                      _cache[28] || (_cache[28] = _createElementVNode("i", { class: "pi pi-chevron-right px-2" }, null, -1)),
                      _createElementVNode("ul", null, [
                        _createElementVNode("li", _hoisted_14, _toDisplayString(_ctx.self.move_data.breadcrumbs[_ctx.self.move_data.breadcrumbs.length - 1].label), 1)
                      ])
                    ])
                  ])
                ]))
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_TableView, {
            selected_document: _ctx.self.move_data.selected_doc,
            "onUpdate:selected_document": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.self.move_data.selected_doc) = $event)),
            stripedRows: false,
            permission_type: _ctx.self.permissionType,
            loading: _ctx.self.move_data.loading,
            items: _ctx.self.move_data.data,
            fields: _ctx.self.move_data.fields,
            pagination: _ctx.self.move_data.pagination,
            disable_select: true,
            actions: _ctx.self.move_data.action_buttons,
            highlight_doc: _ctx.self.move_data.selected_doc
          }, {
            "col-data": _withCtx(({ data }) => [
              _createElementVNode("div", {
                class: _normalizeClass([{'opacity-60': _ctx.self.moving_doc.detail.id == data.data.id}, "flex justify-content-start flex-wrap"]),
                disabled: _ctx.self.moving_doc.detail.id == data.data.id,
                onClick: ($event: any) => (_ctx.self.move_data.selected_doc = data.data)
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex align-items-center justify-content-center document-icon mr-1", _ctx.self.properties.$getContentType(data.data)])
                }, [
                  (_ctx.self.properties.$getContentType(data.data) == 'image')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
                        _createVNode(_component_Image, {
                          width: "32",
                          src: _ctx.self.properties.$getFullPath(data.data.path),
                          class: "flex align-content-center flex-wrap",
                          alt: data.value
                        }, null, 8, ["src", "alt"])
                      ]))
                    : (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        innerHTML: _ctx.self.properties.$getIcon(data.data)
                      }, null, 8, _hoisted_17))
                ], 2),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("span", null, _toDisplayString(data.value), 1)
                ])
              ], 10, _hoisted_15)
            ]),
            "action-buttons": _withCtx(({ data }) => [
              _withDirectives(_createElementVNode("ul", {
                onClick: _cache[25] || (_cache[25] = _withModifiers(() => {}, ["stop"])),
                class: "action-btn"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.self.move_data.action_buttons, (action) => {
                  return _withDirectives((_openBlock(), _createElementBlock("li", {
                    key: action.type,
                    onClick: _withModifiers(($event: any) => (action.command(data)), ["stop"])
                  }, [
                    _createElementVNode("i", {
                      class: _normalizeClass(action.icon)
                    }, null, 2)
                  ], 8, _hoisted_19)), [
                    [
                      _directive_tooltip,
                      action.type,
                      void 0,
                      { bottom: true }
                    ]
                  ])
                }), 128))
              ], 512), [
                [_vShow, _ctx.self.moving_doc.detail.id != data.id && data.type != 'file']
              ])
            ]),
            _: 1
          }, 8, ["selected_document", "permission_type", "loading", "items", "fields", "pagination", "actions", "highlight_doc"]),
          (
            Object.keys(_ctx.self.move_data.selected_doc).length &&
            _ctx.self.moving_doc.detail.id != _ctx.self.move_data.selected_doc.id &&
            _ctx.self.move_data.selected_doc.type != 'file'
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                _createVNode(_component_Button, {
                  class: "mt-2 button-primary",
                  label: "Move Here",
                  onClick: _withModifiers(_ctx.moveDocument, ["stop"])
                }, null, 8, ["onClick"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_ContextMenu, {
        ref: "defaultContext",
        model: _ctx.self.default_context_menu
      }, {
        item: _withCtx(({ label, item, props }) => [
          (item.to)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: item.to,
                custom: ""
              }, {
                default: _withCtx((routerProps) => [
                  _createElementVNode("a", _mergeProps({
                    href: routerProps.href
                  }, props.action), [
                    _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.icon)), null, 16),
                    _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17)
                  ], 16, _hoisted_21)
                ]),
                _: 2
              }, 1032, ["to"]))
            : (_openBlock(), _createElementBlock("a", _mergeProps({
                key: 1,
                href: item.url,
                target: item.target
              }, props.action), [
                _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.icon)), null, 16),
                _createElementVNode("span", _normalizeProps(_guardReactiveProps(props.label)), _toDisplayString(label), 17)
              ], 16, _hoisted_22))
        ]),
        _: 1
      }, 8, ["model"])
    ])
  ]))
}